// worker.js
import * as d3 from "https://esm.fullstackmechanic.com/d3-force";


function calculateImageSizes(galleryRect, images) {
	const availableArea = galleryRect.width * galleryRect.height;
	const totalImageArea = images.reduce((sum, img) => sum + img.width * img.height, 0);
	
	const scaleFactor = Math.sqrt(availableArea / totalImageArea) * 0.5; // 0.6 for spacing

	return images.map(({ id, width, height }) => {
		const scaleRandom = 1 + (Math.random() - 0.5) * 0.2;
		return {
			id,
			width: Math.floor((width * scaleFactor) * scaleRandom),
			height: Math.floor((height * scaleFactor) * scaleRandom)
		}
	});
}

function runSimulation(images, galleryRect, blockRect) {
	const scaledImages = calculateImageSizes(galleryRect, images);

	// Add initial random positions within the gallery rect
	scaledImages.forEach(d => {
		d.x = Math.random() * galleryRect.width;
		d.y = Math.random() * galleryRect.height;
	});

	const blockRectCenterX = blockRect.x - galleryRect.x + blockRect.width / 2;
	const blockRectCenterY = blockRect.y - galleryRect.y + blockRect.height / 2;

	const m = scaledImages[0];
	//console.log("image size: " + Math.sqrt(m.width * m.width + m.height * m.height) / 2)
	//console.log("block size: " + Math.max(blockRect.width, blockRect.height) / 2);

	// Add a fixed node for the block element
	scaledImages.push({
		fx: blockRectCenterX,
		fy: blockRectCenterY,
		width: blockRect.width,
		height: blockRect.height,
		radius: Math.max(blockRect.width, blockRect.height) / 2
	});

	const simulation = d3.forceSimulation(scaledImages)
		.force('charge', d3.forceManyBody().strength(-20))
		.force('collide', d3.forceCollide().radius(d => Math.sqrt(d.width * d.width + d.height * d.height) / 2))
		//.force('x', d3.forceX(galleryRect.width / 2).strength(0.05))
		//.force('y', d3.forceY(galleryRect.height / 2).strength(0.05))
		//.force('avoid', d3.forceCollide().radius(() => Math.max(blockRect.width, blockRect.height) / 2))
		//.force('center', d3.forceCenter(blockRectCenterX, blockRectCenterY))
		.force('boundary', () => {
			for (let node of scaledImages) {
				node.x = Math.max(node.width / 2, Math.min(galleryRect.width - node.width / 2, node.x));
				node.y = Math.max(node.height / 2, Math.min(galleryRect.height - node.height / 2, node.y));
			}
		})//.on('tick', () => { self.postMessage(simulation.nodes().slice(0, -1)); });
		.stop()
		.tick(300);



	// Run the simulation
	//for (let i = 0; i < 300; ++i) simulation.tick();

	// Remove the fixed node and return the results
	return simulation.nodes().slice(0, -1);
}

self.addEventListener('message', (event) => {
	const { images, galleryRect, blockRect } = event.data;
	const results = runSimulation(images, galleryRect, blockRect);
	self.postMessage(results);
});

